<style scoped>
h3 {
  padding: 20px;
}

div.messages {
  /* background-color: lightblue; */
  /* width: 110px; */
  height: 500px;
  overflow: auto;
}

.button-submit {
  width: 100%;
}

.item {
  padding: 10px 30px;
}

.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}

@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@-o-keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}
</style>
<template>
  <v-card class="mx-auto" tile>
    <h3>PROVINSI</h3>
    <table class="table">
      <thead>
        <tr>
          <td>Nama Provinsi</td>
          <td>Tanggal Mulai</td>
          <td>Tanggal Selesai</td>
          <td>Nama Surveyor</td>
          <td colspan="4">Aksi</td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{{ dataSurveyorProvinsi.nama_wilayah }}</td>
          <td><b-form-datepicker v-model="dataSurveyorProvinsi.tanggal_mulai" :min="min" :max="max" locale="in"
              class="mb-2"></b-form-datepicker></td>
          <td><b-form-datepicker v-model="dataSurveyorProvinsi.tanggal_selesai" :min="min" :max="max" locale="in"
              class="mb-2"></b-form-datepicker></td>
          <td>
            <b-form-select v-model="dataSurveyorProvinsi.id_user">
              <b-form-select-option v-for="(value, name) in surveyor" :key="name" :value="value.id">{{ value.name }}
              </b-form-select-option>
            </b-form-select>
          </td>
          <td v-if="dataSurveyorProvinsi.kode_wilayah == 31">
            <b-button :id="'item-' + user.id_ptsp" class="button-submit" variant="primary"
              v-on:click="_save(0, dataSurveyorProvinsi.id, 'prov')">Simpan</b-button>
          </td>
          <td v-else>
            <b-button :id="'item-' + user.id_ptsp" class="button-submit" variant="primary"
              v-on:click="_save(0, dataSurveyorProvinsi.id, 'prov')">Simpan</b-button>
          </td>
          <td v-if="dataSurveyorProvinsi.kode_wilayah == 31">
            <v-btn class="button-submit" tile variant="success"
              @click="kirimprovinsikl(dataSurveyorProvinsi.id_user, dataSurveyorProvinsi.kode_wilayah)"><v-icon
                left>mdi-mail</v-icon>Kirim Data</v-btn>
          </td>
          <td v-else>
            <v-btn class="button-submit" tile variant="success"
              @click="kirimprovinsi(dataSurveyorProvinsi.id_user, dataSurveyorProvinsi.kode_wilayah)"><v-icon
                left>mdi-mail</v-icon>Kirim Data</v-btn>
          </td>
          <td v-if="dataSurveyorProvinsi.kode_wilayah == 31"><v-btn class="button-submit" tile variant="primary"
              @click="urlptsp(dataSurveyorProvinsi.kode_wilayah)"><i class="flaticon-eye"></i>PTSP</v-btn></td>
          <td v-else>
            <v-btn class="button-submit" tile variant="primary" @click="urlptsp(dataSurveyorProvinsi.kode_wilayah)"><i
                class="flaticon-eye"></i>PTSP</v-btn>
          </td>
          <td v-if="dataSurveyorProvinsi.kode_wilayah == 31"><v-btn class="button-submit" tile variant="primary"
              @click="urlppb(dataSurveyorProvinsi.kode_wilayah)"><i class="flaticon-eye"></i>PPB</v-btn></td>
          <td v-else>
            <v-btn class="button-submit" tile variant="primary" @click="urlppb(dataSurveyorProvinsi.kode_wilayah)"><i
                class="flaticon-eye"></i>PPB</v-btn>
          </td>

        </tr>
      </tbody>
    </table>
    <!-- <div class="row item">
      <div class="col">{{ dataSurveyorProvinsi.nama_wilayah }}</div>
      <div class="col"><b-form-datepicker v-model="dataSurveyorProvinsi.tanggal_mulai" :min="min" :max="max" locale="in"
          class="mb-2"></b-form-datepicker></div>
      <div class="col">s.d.</div>
      <div class="col"><b-form-datepicker v-model="dataSurveyorProvinsi.tanggal_selesai" :min="min" :max="max" locale="in"
          class="mb-2"></b-form-datepicker></div>
      <div class="col">
        <b-form-select v-model="dataSurveyorProvinsi.id_user" class="mb-3">
          <b-form-select-option v-for="(value, name) in surveyor" :key="name" :value="value.id">{{ value.name }}
          </b-form-select-option>
        </b-form-select>

      </div>
      <div class="col"><b-button :id="'item-' + user.id_ptsp" class="button-submit" variant="primary"
          v-on:click="_save(0, dataSurveyorProvinsi.id, 'prov')">Simpan</b-button></div>
      <div v-if="dataSurveyorProvinsi.kode_wilayah == 31" class="row">
        <div class="col"><v-btn class="button-submit" tile variant="success"
            @click="kirimprovinsikl(dataSurveyorProvinsi.id_user, dataSurveyorProvinsi.kode_wilayah)"><v-icon
              left>mdi-mail</v-icon>Kirim Data</v-btn></div>
        <div class="col"><v-btn class="button-submit" tile variant="primary"
            @click="urlptsp(dataSurveyorProvinsi.kode_wilayah)"><i class="flaticon-eye"></i>PTSP</v-btn></div>
        <div class="col"><v-btn class="button-submit" tile variant="primary"
            @click="urlppb(dataSurveyorProvinsi.kode_wilayah)"><i class="flaticon-eye"></i>PPB</v-btn></div>
      </div>
      <div v-else class="row">
        <div class="col"><v-btn class="button-submit" tile variant="success"
            @click="kirimprovinsi(dataSurveyorProvinsi.id_user, dataSurveyorProvinsi.kode_wilayah)"><v-icon
              left>mdi-mail</v-icon>Kirim Data</v-btn></div>
        <div class="col"><v-btn class="button-submit" tile variant="primary"
            @click="urlptsp(dataSurveyorProvinsi.kode_wilayah)"><i class="flaticon-eye"></i>PTSP</v-btn></div>
        <div class="col"><v-btn class="button-submit" tile variant="primary"
            @click="urlppb(dataSurveyorProvinsi.kode_wilayah)"><i class="flaticon-eye"></i>PPB</v-btn></div>
      </div>
    </div> -->

    <div v-if="user.id_ptsp != 31">
      <h3>KABUPATEN / KOTA</h3>
      <table class="table">
        <thead>
          <tr>
            <td>Nama Provinsi</td>
            <td>Tanggal Mulai</td>
            <td>Tanggal Selesai</td>
            <td>Nama Surveyor</td>
            <td colspan="4">Aksi</td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, i) in dataSurveyor" :key="i">
            <td>{{ item.nama_wilayah }}</td>
            <td><b-form-datepicker v-model="item.tanggal_mulai" :min="min" :max="max" locale="in"
                class="mb-2"></b-form-datepicker></td>
            <td><b-form-datepicker v-model="item.tanggal_selesai" :min="min" :max="max" locale="in"
                class="mb-2"></b-form-datepicker></td>
            <td>
              <b-form-select v-model="item.id_user" class="mb-3">
                <b-form-select-option v-for="(value, name) in surveyor" :key="name" :value="value.id">{{ value.name }}
                </b-form-select-option>
              </b-form-select>
            </td>
            <td>
              <b-button :id="'item-' + item.kode_wilayah" class="button-submit" variant="primary"
                v-on:click="_save(i, item.id, 'kabkot')">Simpan</b-button>
            </td>
            <td>
              <v-btn class="button-submit" tile variant="success"
                @click="kirimkabkot(dataSurveyor[i].id_user, dataSurveyor[i].kode_wilayah)"><v-icon
                  left>mdi-mail</v-icon>Kirim
                Data</v-btn>
            </td>
            <td>
              <v-btn class="button-submit" tile variant="primary" @click="urlptsp(dataSurveyor[i].kode_wilayah)"><i
                  class="flaticon-eye"></i>PTSP</v-btn>
            </td>
            <td>
              <v-btn class="button-submit" tile variant="primary" @click="urlppb(dataSurveyor[i].kode_wilayah)"><i
                  class="flaticon-eye"></i>PPB</v-btn>
            </td>
          </tr>
        </tbody>
      </table>
      <!-- <div class="row item" v-for="(item, i) in dataSurveyor" :key="item.kode_wilayah">
        <div class="col">{{ item.nama_wilayah }}</div>
        <div class="col"><b-form-datepicker v-model="item.tanggal_mulai" :min="min" :max="max" locale="in"
            class="mb-2"></b-form-datepicker></div>
        <div class="col"><b-form-datepicker v-model="item.tanggal_selesai" :min="min" :max="max" locale="in"
            class="mb-2"></b-form-datepicker></div>
        <div class="col">
          <b-form-select v-model="item.id_user" class="mb-3">
            <b-form-select-option v-for="(value, name) in surveyor" :key="name" :value="value.id">{{ value.name }}
            </b-form-select-option>
          </b-form-select>

        </div>
        <div class="col"><b-button :id="'item-' + item.kode_wilayah" class="button-submit" variant="primary"
            v-on:click="_save(i, item.id, 'kabkot')">Simpan</b-button></div>
        <div class="col"><v-btn class="button-submit" tile variant="success"
            @click="kirimkabkot(dataSurveyor[i].id_user, dataSurveyor[i].kode_wilayah)"><v-icon
              left>mdi-mail</v-icon>Kirim
            Data</v-btn></div>
        <div class="col"><v-btn class="button-submit" tile variant="primary"
            @click="urlptsp(dataSurveyor[i].kode_wilayah)"><i class="flaticon-eye"></i>PTSP</v-btn></div>
        <div class="col"><v-btn class="button-submit" tile variant="primary"
            @click="urlppb(dataSurveyor[i].kode_wilayah)"><i class="flaticon-eye"></i>PPB</v-btn></div>
      </div> -->
    </div>

    <div v-if="user.id_ptsp == 31">
      <h3>KL</h3>
      <table class="table">
        <thead>
          <tr>
            <td>Nama Provinsi</td>
            <td>Tanggal Mulai</td>
            <td>Tanggal Selesai</td>
            <td>Nama Surveyor</td>
            <td colspan="4">Aksi</td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, i) in dataSurveyorKl" :key="i">
            <td>{{ item.nama_wilayah }}</td>
            <td><b-form-datepicker v-model="item.tanggal_mulai" :min="min" :max="max" locale="in"
                class="mb-2"></b-form-datepicker></td>
            <td>
              <b-form-datepicker v-model="item.tanggal_selesai" :min="min" :max="max" locale="in"
                class="mb-2"></b-form-datepicker>
            </td>
            <td>
              <b-form-select v-model="item.id_user" class="mb-3">
                <b-form-select-option v-for="(value, name) in surveyor" :key="name" :value="value.id">{{ value.name }}
                </b-form-select-option>
              </b-form-select>
            </td>
            <td>
              <b-button :id="'item-' + item.kode_wilayah" class="button-submit" variant="primary"
                v-on:click="_save(i, item.id, 'kl')">Simpan</b-button>
            </td>
            <td>
              <v-btn class="button-submit" tile variant="success"
                @click="kirimkl(dataSurveyorKl[i].id_user, dataSurveyorKl[i].kode_wilayah)"><v-icon
                  left>mdi-mail</v-icon>Kirim Data</v-btn>
            </td>
            <td>
              <v-btn class="button-submit" tile variant="primary" @click="urlkl(dataSurveyorKl[i].kode_wilayah)"><i
                  class="flaticon-eye"></i>PPB KL</v-btn>
            </td>
          </tr>

        </tbody>
      </table>
      <!-- <div class="row item" v-for="(item, i) in dataSurveyorKl" :key="item.kode_wilayah">
        <div class="col">{{ item.nama_wilayah }}</div>
        <div class="col"><b-form-datepicker v-model="item.tanggal_mulai" :min="min" :max="max" locale="in"
            class="mb-2"></b-form-datepicker></div>
        <div class="col"><b-form-datepicker v-model="item.tanggal_selesai" :min="min" :max="max" locale="in"
            class="mb-2"></b-form-datepicker></div>
        <div class="col">
          <b-form-select v-model="item.id_user" class="mb-3">
            <b-form-select-option v-for="(value, name) in surveyor" :key="name" :value="value.id">{{ value.name }}
            </b-form-select-option>
          </b-form-select>

        </div>
        <div class="col"><b-button :id="'item-' + item.kode_wilayah" class="button-submit" variant="primary"
            v-on:click="_save(i, item.id, 'kl')">Simpan</b-button></div>
        <div class="col"><v-btn class="button-submit" tile variant="success"
            @click="kirimkl(dataSurveyorKl[i].id_user, dataSurveyorKl[i].kode_wilayah)"><v-icon
              left>mdi-mail</v-icon>Kirim Data</v-btn></div>
        <div class="col"><v-btn class="button-submit" tile variant="primary"
            @click="urlkl(dataSurveyorKl[i].kode_wilayah)"><i class="flaticon-eye"></i>PPB KL</v-btn></div>
      </div> -->
    </div>

  </v-card>
</template>
<script>
import Vue from 'vue';
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import axios from 'axios';
import VueLoading from 'vuejs-loading-plugin'

Vue.use(VueLoading)

// overwrite defaults
Vue.use(VueLoading, {
  dark: true, // default false
  text: 'Loading', // default 'Loading'
  loading: true, // default false
  //customLoader: myVueComponent, // replaces the spinner and text with your own
  background: 'rgb(255,255,255)', // set custom background
  classes: ['myclass'] // array, object or string
})
// import AdvancedTableWidget2 from "@/view/content/widgets/advance-table/Widget2.vue";
// import AdvancedTableWidget3 from "@/view/content/widgets/advance-table/Widget3.vue";
// import MixedWidget1 from "@/view/content/widgets/mixed/Widget1.vue";
// import ListWidget1 from "@/view/content/widgets/list/Widget1.vue";
// import ListWidget3 from "@/view/content/widgets/list/Widget3.vue";
// import ListWidget4 from "@/view/content/widgets/list/Widget4.vue";
// import ListWidget8 from "@/view/content/widgets/list/Widget8.vue";
// import ListWidget9 from "@/view/content/widgets/list/Widget9.vue";
// import StatsWidget7 from "@/view/content/widgets/stats/Widget7.vue";
// import StatsWidget12 from "@/view/content/widgets/stats/Widget12.vue";
// import StatsWidget13 from "@/view/content/widgets/stats/Widget13.vue";

export default {
  name: "mapping",
  data() {
    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
    // 15th two months prior
    const minDate = new Date('2024-01-24')

    // 15th in two months
    const maxDate = new Date('2024-08-13')
    return {
      urlpenilaian: localStorage.getItem('baseapipenilaian'),
      url: localStorage.getItem('baseapi'),
      token: localStorage.getItem('id_token'),
      user: JSON.parse(localStorage.getItem('user')),
      dataSurveyorProvinsi: {},
      dataSurveyor: [],
      dataSurveyorKl: [],
      loader: null,
      loading: false,
      test: 'hai',
      id_user: '',
      roles: [],
      search: '',
      provinsi: [],
      kota: [],
      tanggal_mulai: '',
      tanggal_selesai: '',
      source_user_id: '',
      surveyor: [],
      headers: [
        {
          text: 'Nama',
          align: 'left',
          sortable: false,
          value: 'nama_wilayah',
        },
        { text: 'Tanggal', value: '' },
        { text: 'Nama Surveyor', value: '' },
        { text: 'Aksi', value: '' }

      ],
      iduserrr: '',
      min: minDate,
      max: maxDate
    }
  },
  components: {
    // AdvancedTableWidget2,
    // AdvancedTableWidget3,
    // MixedWidget1,
    // ListWidget1,
    // ListWidget3,
    // ListWidget4,
    // ListWidget8,
    // ListWidget9,
    // StatsWidget7,
    // StatsWidget12,
    // StatsWidget13
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Mapping" }]);
    // this.loaddata()
    this.getuser();
    this.getprovinsi();
    this.getkota();
    this.getkl();
    // if (localStorage.getItem('id_token')) {
    //     // The page was just reloaded. Clear the value from local storage
    //     // so that it will reload the next time this page is visited.
    //     this.getuser(this.user);
    //     // localStorage.removeItem('reloaded');
    // } else {
    //     // Set a flag so that we know not to reload the page twice.
    //     localStorage.setItem('id_token', '1');
    //     location.reload();
    // }
  },
  methods: {
    // http://penkin-penilaian.mysurvey.id/verifikasi_ppb_pemdas?filter=idpmppb,=,`+this.idppb+`;tahun,=,`+this.tahun+`;idptsp,=,`+this.user.id_ptsp
    urlkl(kode_wilayah) {
      let routeData = this.$router.resolve(
        {
          path: `/mapping/detailpmppbkl/${kode_wilayah}`,
          // query: {'dataParameter': kode_wilayah}
        });
      window.open(routeData.href, '_blank');
      //                window.open(`/ppk/detailpm/${kode_wilayah}`, '_blank')
      ``
    },
    urlppb(kode_wilayah) {
      let routeData = this.$router.resolve(
        {
          path: `/mapping/detailpmppb/${kode_wilayah}`,
          // query: {'dataParameter': kode_wilayah}
        });
      window.open(routeData.href, '_blank');
      //                window.open(`/ppk/detailpm/${kode_wilayah}`, '_blank')
      ``
    },
    urlptsp(kode_wilayah) {
      let routeData = this.$router.resolve(
        {
          path: `/mapping/detailpm/${kode_wilayah}`,
          // query: {'dataParameter': kode_wilayah}
        });
      window.open(routeData.href, '_blank');
      //                window.open(`/ppk/detailpm/${kode_wilayah}`, '_blank')
      ``
    },
    kirimprovinsi(iduserr, kodewilayah) {
      this.$loading(true);
      const iduser = iduserr;
      const id = kodewilayah;
      const role = 'surveyor_pemda';
      // const tanggal_selesai = this.tanggal_selesai;
      axios
        .post(
          this.url + "/mailing_surveyor",
          {
            iduser,
            id,
            role,
            // tanggal_selesai,

          }, {
          headers: {
            "Content-type": "application/json",
            "xth": this.token,
          }
        }
        )
        .then((res) => {
          this.$loading(false);
          this.$bvToast.toast('Berhasil Dikirim', {
            title: `Success `,
            variant: `success`,
            solid: true
          })
          console.log(res);
          // this.loaddata()
          // localStorage.setItem('idprofil', JSON.stringify(res.data.id))
          return res;
        })
        .catch((err) => {
          this.$loading(false);
          console.log(err)
          this.$bvToast.toast(err.response.data.message, {
            title: `Failed `,
            variant: `danger`,
            solid: true
          })
          return err;
        });
    },
    kirimprovinsikl(iduserr, kodewilayah) {
      this.$loading(true);
      const iduser = iduserr;
      const id = kodewilayah;
      const role = 'surveyor_kl';
      // const tanggal_selesai = this.tanggal_selesai;
      axios
        .post(
          this.url + "/mailing_surveyor",
          {
            iduser,
            id,
            role,
            // tanggal_selesai,

          }, {
          headers: {
            "Content-type": "application/json",
            "xth": this.token,
          }
        }
        )
        .then((res) => {
          this.$loading(false);
          this.$bvToast.toast('Berhasil Dikirim', {
            title: `Success `,
            variant: `success`,
            solid: true
          })
          console.log(res);
          // this.loaddata()
          // localStorage.setItem('idprofil', JSON.stringify(res.data.id))
          return res;
        })
        .catch((err) => {
          this.$loading(false);
          console.log(err)
          this.$bvToast.toast(err.response.data.message, {
            title: `Failed `,
            variant: `danger`,
            solid: true
          })
          return err;
        });
    },
    kirimkl(iduserr, kodewilayah) {
      this.$loading(true);
      const iduser = iduserr;
      const id = kodewilayah;
      const role = 'surveyor_kl';
      // const tanggal_selesai = this.tanggal_selesai;
      axios
        .post(
          this.url + "/mailing_surveyor",
          {
            iduser,
            id,
            role,
            // tanggal_selesai,

          }, {
          headers: {
            "Content-type": "application/json",
            "xth": this.token,
          }
        }
        )
        .then((res) => {
          this.$loading(false);
          this.$bvToast.toast('Berhasil Dikirim', {
            title: `Success `,
            variant: `success`,
            solid: true
          })
          console.log(res);
          // this.loaddata()
          // localStorage.setItem('idprofil', JSON.stringify(res.data.id))
          return res;
        })
        .catch((err) => {
          this.$loading(false);
          console.log(err)
          this.$bvToast.toast(err.response.data.message, {
            title: `Failed `,
            variant: `danger`,
            solid: true
          })
          return err;
        });
    },
    kirimkabkot(iduserr, kodewilayah) {
      this.$loading(true);
      const iduser = iduserr;
      const id = kodewilayah;
      const role = 'surveyor_pemda';
      // const tanggal_selesai = this.tanggal_selesai;
      axios
        .post(
          this.url + "/mailing_surveyor",
          {
            iduser,
            id,
            role,
            // tanggal_selesai,

          }, {
          headers: {
            "Content-type": "application/json",
            "xth": this.token,
          }
        }
        )
        .then((res) => {
          this.$loading(false);
          this.$bvToast.toast('Berhasil Dikirim', {
            title: `Success `,
            variant: `success`,
            solid: true
          })
          console.log(res);
          // this.loaddata()
          // localStorage.setItem('idprofil', JSON.stringify(res.data.id))
          return res;
        })
        .catch((err) => {
          this.$loading(false);
          console.log(err)
          this.$bvToast.toast(err.response.data.message, {
            title: `Failed `,
            variant: `danger`,
            solid: true
          })
          return err;
        });
    },
    getPenempatan() {
      axios.get(this.url + `/penempatan?filter=source_user_id,=,` + this.user.id, {
        headers: {
          'xth': this.token
        }
      }).then(response => {
        // axios.get(`http://penkin-penilaian.mysurvey.id/sdm/?filter=idpm,=,`+this.idpm+`;bagian,=,Kepala Dinas`).then(response => {
        let result = response.data;
        console.log(result);
        for (let i = 0; i < result.data.length; i++) {
          if (!result.data[i]['kode_wilayah']) {
            continue;
          }
          if (result.data[i]['kode_wilayah'].length == 2) {
            this.dataSurveyorProvinsi = result.data[i];
          } else if (result.data[i]['kode_wilayah'].length == 3) {
            for (let j = 0; j < this.dataSurveyorKl.length; j++) {
              if (this.dataSurveyorKl[j]['kode_wilayah'] == result.data[i]['kode_wilayah']) {
                this.dataSurveyorKl[j]['id'] = result.data[i]['id'];
                this.dataSurveyorKl[j]['tanggal_mulai'] = result.data[i]['tanggal_mulai'];
                this.dataSurveyorKl[j]['tanggal_selesai'] = result.data[i]['tanggal_selesai'];
                this.dataSurveyorKl[j]['id_user'] = result.data[i]['id_user'];
                this.dataSurveyorKl[j]['name_user'] = result.data[i]['name_user'];
              }
            }
          } else {
            for (let j = 0; j < this.dataSurveyor.length; j++) {
              if (this.dataSurveyor[j]['kode_wilayah'] == result.data[i]['kode_wilayah']) {
                this.dataSurveyor[j]['id'] = result.data[i]['id'];
                this.dataSurveyor[j]['tanggal_mulai'] = result.data[i]['tanggal_mulai'];
                this.dataSurveyor[j]['tanggal_selesai'] = result.data[i]['tanggal_selesai'];
                this.dataSurveyor[j]['id_user'] = result.data[i]['id_user'];
                this.dataSurveyor[j]['name_user'] = result.data[i]['name_user'];
              }
            }
          }
        }
      });
    },
    getprovinsi() {
      axios.get(this.url + '/provinsi?id=' + this.user.id_ptsp, {
        headers: {
          'xth': this.token
        }
      }).then(response => {
        this.dataSurveyorProvinsi = {
          "id_user": "",
          "name_user": "",
          "kode_wilayah": response.data.data.id,
          "nama_wilayah": response.data.data.name,
          "source_user_id": this.user.id,
          "source_user_name": this.user.name,
          "tanggal_mulai": "",
          "tanggal_selesai": "",
          "message": "simpan"
        }
      });
    },
    getkota() {
      axios.get(this.url + `/kabkota_join/` + this.user.id_ptsp, {
        headers: {
          'xth': this.token
        }
      }).then(response => {
        this.kota = response.data;
        for (let i = 0; i < response.data.length; i++) {
          let temp = {
            "id_user": "",
            "name_user": "",
            "kode_wilayah": response.data[i].id,
            "nama_wilayah": response.data[i].name,
            "source_user_id": this.user.id,
            "source_user_name": this.user.name,
            "tanggal_mulai": "",
            "tanggal_selesai": ""
          }
          this.dataSurveyor.push(temp);
        }

      });
    },
    getkl() {
      axios.get(this.url + `/profil_ppb_kl`, {
        headers: {
          'xth': this.token
        }
      }).then(response => {
        for (let i = 0; i < response.data.data.length; i++) {
          let temp = {
            "id_user": "",
            "name_user": "",
            "kode_wilayah": response.data.data[i].idkl,
            "nama_wilayah": response.data.data[i].nama_kl,
            "source_user_id": this.user.id,
            "source_user_name": this.user.name,
            "tanggal_mulai": "",
            "tanggal_selesai": ""
          }
          this.dataSurveyorKl.push(temp);
        }
        this.getPenempatan();
      });
    },
    getuser() {
      if (this.user.id_ptsp == 31) {
        axios.get(this.url + `/users?filter=id_role,=,surveyor_kl;id_ptsp,=,` + this.user.id_ptsp + `;status,!=,created`, {
          headers: {
            'xth': this.token
          }
        }).then(response => {
          // axios.get(`http://penkin-penilaian.mysurvey.id/sdm/?filter=idpm,=,`+this.idpm+`;bagian,=,Kepala Dinas`).then(response => {
          this.surveyor = response.data.data;

        });
      } else {
        axios.get(this.url + `/users?filter=id_role,=,surveyor_pemda;id_ptsp,=,` + this.user.id_ptsp + `;status,!=,created`, {
          headers: {
            'xth': this.token
          }
        }).then(response => {
          // axios.get(`http://penkin-penilaian.mysurvey.id/sdm/?filter=idpm,=,`+this.idpm+`;bagian,=,Kepala Dinas`).then(response => {
          this.surveyor = response.data.data;
          console.log('ini')

        });
      }
      //    axios.get(`http://103.55.36.170:8000/users/?filter=id_role,=,surveyor_pemda;id_ptsp,=,`+this.user.id_ptsp).then(response => {
      //     // axios.get(`http://penkin-penilaian.mysurvey.id/sdm/?filter=idpm,=,`+this.idpm+`;bagian,=,Kepala Dinas`).then(response => {
      //     this.surveyor = response.data.data;

      // });
    },
    _save(listId, dataId, type) {
      let apiMethod = "post"
      let apiUrl = this.url + '/penempatan';
      let apiHeaders = {
        'Content-Type': 'application/json',
        // 'Access-Control-Allow-Origin': '*',
      };
      if (dataId) {
        apiMethod = "put";
        apiUrl = apiUrl + '/' + dataId;
      }
      let data = {};
      if (type == "kabkot") {
        data = this.dataSurveyor[listId];
      }
      if (type == "kl") {
        data = this.dataSurveyorKl[listId];
      }
      if (type == "prov") {
        data = this.dataSurveyorProvinsi;
      }
      let root = this;
      let buttonComponent = document.querySelector('#item-' + data['kode_wilayah']);
      buttonComponent.loading = true;
      axios({
        method: apiMethod,
        url: apiUrl,
        data: JSON.stringify(data),
        headers: apiHeaders
      }).then(function (response) {
        buttonComponent.innerHTML = "Data Berhasil Tersimpan";
        buttonComponent.style = "background:#4caf50";
        root.loading = false;

        if (type == "kabkot") {
          root.dataSurveyor[listId] = response.data.data;
        }
        if (type == "kl") {
          root.dataSurveyorKl[listId] = response.data.data;
        }
        if (type == "prov") {
          root.dataSurveyorProvinsi = response.data.data;
        }
        console.log(response)
        setTimeout(function () {
          buttonComponent.innerHTML = "Simpan";
          buttonComponent.style = "background:#3699ff";
          buttonComponent.loading = false;
        }, 3000);
      }).catch(function (response) {
        buttonComponent.innerHTML = "Gagal";
        buttonComponent.style = "background:#ff5252";
        setTimeout(function () {
          buttonComponent.innerHTML = "Simpan";
          buttonComponent.style = "background:#3699ff";
          buttonComponent.loading = false;
        }, 3000);
      });




    },
    setActiveTab1(event) {
      this.tabIndex = this.setActiveTab(event);
    },
    setActiveTab2(event) {
      this.tabIndex2 = this.setActiveTab(event);
    },
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set current active tab
      event.target.classList.add("active");

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("data-tab"));
    }
  },
  created() {
    //   this.getuser(this.user)
    // this.getlocaldata()
  }
};
</script>
